import http from '@/_helpers/http';

export default class cardService {
  /**
   * Pobiera szczegóły danej karty
   * @param cardNr - nrr karty
   * @returns promise
   */
  getCardDetails(cardNr) {
    return http.axios.get(`${http.api.ACTION.CARD_DETAILS.replace('{cardNr}', cardNr)}`).then(response => response.data);
  }

  /**
   * Pobiera listę kart na daną sesję
   * @returns promise
   */
  getCardList() {
    return http.axios.get(`${http.api.ACTION.CARD_LIST}`)
      .then(response => response.data);
  }
}
