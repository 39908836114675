/**
 * odpowiada za dzialanie aplikacji w trybie PWA - na razie nieuzywane
 * https://enux.pl/article/pl/2018-04-03/od-responsywnej-witryny-do-pwa-w-jeden-dzie%C5%84
 */
if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/service-worker.js?v2', {
    scope: '.',
  }).then((registration) => {
    // Registration was successful
    console.log('ServiceWorker registration successful with scope: ', registration.scope);
  }, (err) => {
    // registration failed :(
    console.log('ServiceWorker registration failed: ', err);
  });
}
