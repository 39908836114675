<template>
  <div id="app">
    <div class="w-100 h-100 position-absolute text-center loading-overlay" v-if="loading || !$store.state.settings">
      <div class="spinner-border loading-spinner" role="status" size="lg">
        <span class="sr-only">Ładowanie.....</span>
      </div>
    </div>
    <template v-if="$store.state.settings">
      <cookie-alert/>
      <notifications group="default"/>
      <navigation/>
      <router-view/>
    </template>
    <Footer/>
  </div>
</template>
<script>
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import CookieAlert from '@/components/CookieAlert.vue';

import UserService from '@/_services/user.service';
import WebService from "@/_services/web.service";

const userService = new UserService();
const webService = new WebService();

export default {
  name: 'home',
  components: {
    Navigation,
    CookieAlert,
    Footer,
  },
  /**
   * tu ogolnie mozna ladowac wszystkie metody ktore mają sie wykonać przed inicjacją projektu
   * */
  async created() {
    await webService.getSettings()
      .then((data) => {

        Object.values(data.cms_pages)
          .forEach((navigation) => {
            this.$store.state.navigation.push(navigation);
          });
        this.$store.commit('setSettings', data);
      });
    /**
     * aby utrzymywać sesję przy życiu, co jakiś czas wysyłamy żądanie do serwera
     */
    userService.checkIsLogged();
    setInterval(() => {
      userService.checkIsLogged();
    }, 120000);
  },
  computed: {
    loading() {
      return this.$store.state.loading;
    },
  },
};
</script>

<style lang="scss">
@import 'assets/scss/main.scss';
</style>
