<template>
  <b-navbar toggleable="lg" type="light" variant="light" sticky class="navbar-top">
    <b-container>
      <b-navbar variant="faded" type="light">
        <b-navbar-brand :to="{ name: 'home'}">
          <b-img :src="$store.state.settings.images.logo_image" alt="Logo" class="logo"/>
        </b-navbar-brand>
      </b-navbar>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item :to="{ name: 'promotions'}" exact-active-class="active">Promocje</b-nav-item>
          <b-nav-item :to="{ name: 'shops'}" exact-active-class="active">Sklepy</b-nav-item>
          <b-nav-item v-for="page in navigation" :key="page.id" :href="page.url" v-if="page.url"
                      exact-active-class="active">{{ page.name }}
          </b-nav-item>
          <b-nav-item v-else :to="page.to"
                      exact-active-class="active">{{ page.name }}
          </b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto account border border-primary rounded">
          <b-img src="/img/icons/person-icon.png" class="d-none d-md-block"></b-img>
          <b-nav-item :to="`/register`" v-if="!isLogged" exact-active-class="active">Rejestracja
          </b-nav-item>
          <b-nav-item :to="`/login`" v-if="!isLogged" exact-active-class="active">Logowanie
          </b-nav-item>
          <b-nav-item-dropdown right v-if="isLogged" class="w-100">
            <template slot="button-content"><em>Panel klienta</em></template>
            <b-dropdown-item :to="`/user-data`" exact-active-class="active">Konto online
            </b-dropdown-item>
            <b-dropdown-item href="#" @click="logout">Wyloguj</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
        <b-navbar-nav>
          <!--        todo ten element wymaga dokonczenia od strony api i frontendu -->
          <!--        <card-details :primary="1" v-if="$store.state.settings.card_settings.points_online_checker"></card-details>-->
        </b-navbar-nav>
      </b-collapse>
    </b-container>

  </b-navbar>

</template>

<script>
import CardDetails from '@/components/CardDetails.vue';
import NotifyService from '../_services/notify';
import UserService from '../_services/user.service';

const notify = new NotifyService();
const userService = new UserService();
export default {
  components: {
    CardDetails,
  },
  data() {
    return {
      isLogged: false,
    };
  },
  mounted() {
    this.setLoggedStatus();
  },
  created() {
    setInterval(() => {
      this.setLoggedStatus();
    }, 300);
  },
  methods: {
    logout() {
      notify.success('OK', 'Wylogowano pomyślnie');
      userService.clearSession();
      this.$router.push('/');
    },
    setLoggedStatus() {
      this.isLogged = (localStorage.getItem('token') !== null && localStorage.getItem('step') === null);
    },
  },
  computed: {
    navigation() {
      return this.$store.state.navigation;
    },
  },
};
</script>
