<template>
  <b-container fluid>
      <b-form inline class="cardChecker">
        <b-input-group prepend="Sprawdź stan punktowy"   class="mt-3 d-none d-md-flex">
          <b-form-input v-model="card.nr" placeholder="Wpisz numer karty"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="getCardDetails" :disabled="loading">
              <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
              Sprawdź
            </b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="d-block d-md-none w-100 mt-4 text-center">
          <b>Sprawdź stan punktowy</b>
          <b-form-input v-model="card.nr" placeholder="Wpisz numer karty" class="mt-2"></b-form-input>
          <b-button  class="w-50 text-center mt-2" variant="primary" @click="getCardDetails" :disabled="loading">
            <b-spinner type="grow" label="Spinning" small v-if="loading"></b-spinner>
            Sprawdź
          </b-button>
        </div>
      </b-form>
      <b-modal v-model="modalShow" ok-only>
        <template v-slot:modal-title>
          Sprawdź punkty
          <code>{{card.nr}}</code>
        </template>
        Na karcie znajduje się <strong>{{ details.points }}</strong> punktów.
      </b-modal>
    <div class="clearfix">
    </div>
  </b-container>
</template>

<script>
import CardService from '../_services/card.service';
import Notify from '../_services/notify';

const notify = new Notify();
const cardService = new CardService();
export default {
  props: {
    primary: { // zeby przy kilku komponentach tego typu nie zmienialo za kazdym razem URL
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modalShow: false,
      card: {
        nr: '',
      },
      details: {},
      validation: {
        nr: null,
      },
      loading: false,
    };
  },
  watch: {
    modalShow(newVal, oldVal) {
      if (oldVal && !newVal && this.primary) {
        const query = { ...this.$route.query };
        delete query.card_nr;
        this.$router.replace({ query });
        // this.$router.replace({ query: { card_nr: null } });
      }
    },
  },
  beforeCreate() {
    setTimeout(() => {
      if (this.primary && this.$route.query.card_nr) {
        this.card.nr = this.$route.query.card_nr;
        this.getCardDetails();
      }
    }, 150);
  },
  methods: {
    getCardDetails() {
      if (this.primary) {
        const query = { ...this.$route.query };
        query.card_nr = this.card.nr;
        this.$router.replace({ query });
      }
      if (this.card.nr.length < 9) {
        notify.error('Błąd', 'Podany nr karty jest błędny');
        return;
      }
      this.loading = true;
      cardService.getCardDetails(this.card.nr).then((data) => {
        this.details = data;
        this.modalShow = true;
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
<style scoped lang="scss">
  .cardChecker {
    margin-bottom:15px;
  }
</style>
