import http from '@/_helpers/http';
import store from '@/store';

export default class userService {
  /**
   * loguje
   * @param form
   * @returns Promise
   */
  loginSend(form) {
    return http.axios.post(`${http.api.ACTION.LOGIN}`, {
      login: `${store.state.settings.card_settings.prefix}${form.login}`,
      password: form.password,
    });
  }

  clearCardNumber(card_nr) {
    let card_settings = store.state.settings.card_settings;
    if (card_nr && card_nr.length === card_settings.length) {
      return card_nr.substring(3, 20);
    }
    return card_nr
  }

  /**
   * pobiera dane uczestnika i je zwraca w formie tablicy json jesli sa poprawne
   */
  getUserData() {
    return http.axios.get(`${http.api.ACTION.USER_DETAILS}`)
      .then((response) => response.data.data);
  }

  /**
   * pobiera transakcje uczestnika na podstawie Roku i Miesiąca, jeśli brak - pobierze wszystkie.
   * @object filters
   * @returns PROMISE
   */
  getTransactions() {
    return http.axios.get(`${http.api.ACTION.TRANSACTION_LIST}`).then((response) => response.data);
  }

  /** pobiera zamówienia uczestnika na podstawie Roku i Miesiąca, jeśli brak - pobierze wszystkie.
   * @object filters
   * @returns PROMISE
   */
  getOrders() {
    return http.axios.get(`${http.api.ACTION.ORDER_LIST}`).then((response) => response.data);
  }

  getPointsExtra(filters) {
    return http.axios.get(`${http.api.ACTION.POINTS_EXTRA_LIST.replace('{Year}', filters.year.value)
      .replace('{Month}', filters.month.value)}`)
      .then((response) => response.data.data);
  }

  /**
   * sprawdza czy user jest zalogowany i ma aktualny klucz, jeśli nie, interceptor go wyłapie
   */
  checkSession() {
    return http.axios.get(`${http.api.ACTION.USER_CHECK_SESSION}`)
      .then((response) => response.data);
  }

  /**
   * usuwa dane z localstorage, zostawia tylko to co potrzebne
   */
  clearSession() {
    const shopPromotion = localStorage.getItem('shop_promotion');
    localStorage.clear();
    localStorage.setItem('shop_promotion', shopPromotion);
    store.dispatch('clearSession');
  }

  checkIsLogged() {
    // jesli token jest pusty, to nie ma sensu sprawdzać
    if (localStorage.getItem('token') == null) {
      return;
    }
    this.checkSession();
  }


  /**
   * pobiera dane uczestnika i je zwraca w formie tablicy json jesli sa poprawne
   */
  changeData(fields) {
    return http.axios.patch(`${http.api.ACTION.USER_UPDATE}`, fields);
  }

  getSexOptions() {
    return [
      {
        value: 0,
        text: 'Wybierz swoją płeć',
      },
      {
        value: 1,
        text: 'Kobieta',
      },
      {
        value: 2,
        text: 'Mężczyzna',
      },
    ];
  }
}
