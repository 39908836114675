import Axios from 'axios';
import store from '../store';
import router from '@/router';
import api from './api';
import apiConstants from '@/constants/api';
import NotifyService from '@/_services/notify';

const notify = new NotifyService();
notify.canDuplicate = false; // zeby nie spamowalo roznymi bledami
/**
 * pobranie klucza autoryzujacego jesli jest
 * @returns {string|boolean}
 */
function auth() {
  if (localStorage.getItem('token')) {
    return `Bearer ${localStorage.getItem('token')}`;
  }
  return false;
}

/**
 * czerwone błędy na górze (powiadomienia)
 * @param variable
 * @returns {boolean|*}
 */
function loopMessages(variable) {
  if (typeof variable !== 'object') {
    return false;
  }
  let message = '';
  Object.keys(variable)
    .forEach((key) => {
      message += `${variable[key]} <br/>`;
    });
  return notify.error('Błąd', message);
}

/**
 * utworzenie instancji axiosa w zmiennej
 * @type {AxiosInstance}
 */

const HTTP = Axios.create({
  baseURL: api.BASE_URL,
  crossDomain: true,
  timeout: 10000, // po 10 sekundach nastąpi error
  transformRequest: [(data, headers) => {
    // eslint-disable-next-line no-param-reassign
    headers.Authorization = auth();
    return JSON.stringify(data);
  }],
  headers: {'Content-Type': 'application/json'},
});
/**
 * Globalny interceptor do obsługi różnych wyjątków
 * TODO: Dorobić mechanizm przedłużenia tokena w przypadku 401 i ponowienie requesta - do omówienia jeszcze
 */
HTTP.interceptors.response.use(response => response, (error) => {
  store.state.loading = false;
  // całkowity brak odpowiedzi w żądanym timeout, zwracamy promise i nic sie nie dzieje dalej
  if (typeof error.response === 'undefined') {
    notify.error('Błąd sieci', 'Problem z połączeniem internetowym! Spróbuj odświeżyć stronę. <a href="/">Odśwież stronę</a>');
    return Promise.reject(error);
  }
  // blad 401 - problem z kluczem, brak autoryzacji
  if (error.response.status === 401 && localStorage.getItem('token') != null) {
    notify.error('Błąd', 'Sesja wygasła. Należy się przelogować!');
    localStorage.clear(); // Dla pewnosci lepiej wszystko usunąć
    router.push('/login');
  }
  // proba zlapania globalnej wiadomosci
  const no_display_messages = ['The given data was invalid.'];

  if (typeof error.response.data.message !== 'undefined' && !no_display_messages.includes(error.response.data.message)) {
    notify.error('Błąd', error.response.data.message);
  }

  return Promise.reject(error);
});
HTTP.interceptors.request.use(
  config => {
    config.headers['x-tenant-id'] = apiConstants.tenant_id;
    return config;
  }
);

/**
 * eksport zmiennych:
 * HTTP/axios - AXIOS
 * api
 */
export default {
  api,
  axios: HTTP,
  state: store.state,
};
