import { render, staticRenderFns } from "./CookieAlert.vue?vue&type=template&id=3a5eac52&scoped=true&"
import script from "./CookieAlert.vue?vue&type=script&lang=js&"
export * from "./CookieAlert.vue?vue&type=script&lang=js&"
import style0 from "./CookieAlert.vue?vue&type=style&index=0&id=3a5eac52&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a5eac52",
  null
  
)

export default component.exports