import api from '@/constants/api';

/**
 * Domyślne akcje URL do określonych czynności, rejestracja, logowanie itp.
 * @string akcja
 */
const ACTION = {
  REGISTER_FIRST: 'register/first',
  REGISTER_SECOND: 'register/second',
  REGISTER_CONFIRM: 'register/confirm',
  REGISTER_TOKEN_CHECK: 'register/check-sms-code',
  USER_DETAILS: 'auth/edit',
  USER_CHECK_SESSION: 'auth/check',
  CARD_DETAILS: 'cards/{cardNr}/details',
  CARD_LIST: 'users/cards',
  USER_UPDATE: 'auth/update',
  LOGOUT: 'auth/logout',
  LOGIN: 'auth/login',
  SHOPS: 'shops',
  SHOPS_LIST: 'shops',
  PROMOTION_LIST: 'promotions?shop_id={ShopId}&desktop=1',
  CONTACT_SEND: 'contact/send',
  TRANSACTION_LIST: 'auth/transactions?limit=100000',
  ORDER_LIST: 'auth/orders?limit=100000',
  POINTS_EXTRA_LIST: 'users/pointsExtra?limit=100000',
  CMS_PAGE: 'pages/seo/{CmsId}',
  FRONT_SETTINGS: 'settings/front_app_settings',
};
/**
 * dopisanie URL jako prefix
 */
Object.keys(ACTION).forEach((key) => {
  ACTION[key] = api.api + ACTION[key];
});

/**
 * eksport zmiennych
 */
export default {
  URL: api.api,
  ACTION,
};
