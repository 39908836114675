import Vue from 'vue';
import Router from 'vue-router';
import Notifications from 'vue-notification';
import api from '@/constants/api';

Vue.use(Router);
Vue.use(Notifications);
const titlePrefix = `${api.api_full.program_name} - `;
const titleSuffix = '';

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      meta: {
        title: 'Strona główna',
      },
      path: '/',
      name: 'home',
      component: () => import('./views/Home.vue'),
    },
    {
      meta: {
        title: 'Logowanie',
      },
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
    },
    {
      meta: {
        title: 'Rejestracja',
      },
      path: '/register',
      name: 'signup',
      component: () => import('./views/Signup.vue'),
    },
    {
      meta: {
        title: 'Konto online',
      },
      path: '/user-data',
      name: 'User',
      component: () => import('./views/User.vue'),
    },
    {
      meta: {
        title: '',
      },
      path: '/page/:seo',
      name: 'cms',
      component: () => import('./views/Cms.vue'),
    },
    {
      meta: {
        title: 'Promocje',
      },
      path: '/promotions',
      name: 'promotions',
      component: () => import('./views/Promotions.vue'),
    },
    {
      meta: {
        title: 'Nasze sklepy',
      },
      path: '/shops',
      name: 'shops',
      component: () => import('./views/Shops.vue'),
    },
    {
      path: '/kontakt',
      name: 'contact',
      meta: {
        title: 'Kontakt',
      },
      component: () => import('./views/Contact.vue'),
    },
    {
      path: '/404',
      name: 'NotFound',
      meta: {
        title: 'Podstrony nie znaleziono',
      },
      component: () => import('./views/NotFound.vue'),
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

router.beforeEach((to, from, next) => {
  // strony na ktore moze wbic tylko niezalogowany
  const noLoggedPages = ['register', 'login'];
  // strony na tkroe moze wbic tylko zalogowany
  const loggedPages = ['user', 'about', 'user-data'];
  const authRequired = loggedPages.includes(to.path.replace(/\//g, ''));
  const noAuthRequired = noLoggedPages.includes(to.path.replace(/\//g, ''));
  // proba pobrania tytulu strony
  const nearestWithTitle = to.matched.slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  if (authRequired && localStorage.getItem('token') == null) {
    setTimeout(() => {
      Vue.notify({
        group: 'stock',
        title: 'Problem',
        text: 'Aby przejść na tą stronę musisz być zalogowany!',
      });
    }, 100);
    router.push('/login');
    return;
  }
  if (noAuthRequired && localStorage.getItem('token') != null && localStorage.getItem('step') == null) {
    setTimeout(() => {
      Vue.notify({
        group: 'stock',
        title: 'Problem',
        text: 'Strona nie jest dla zalogowanych!',
      });
    }, 100);
    router.push('/');
    return;
  }
  if (nearestWithTitle) document.title = titlePrefix + nearestWithTitle.meta.title + titleSuffix;
  next();
});

export default router;
