<template>
  <footer>
    <b-container class="">
      <b-row>
        <b-col>
          &copy; Wszelkie Prawa Zastrzeżone.
        </b-col>
        <b-col class="text-right">
          <b-button @click="breakSession" size="sm" style="padding:0;font-size:10px;opacity:0;width:20px">&nbsp;
          </b-button>
          <a target="_blank" data-toggle="tooltip" data-placement="top" href="http://www.profitcard.pl/"
             title="Nowoczesny marketing relacji, programy lojalnościowe - projektowanie, wdrażanie, zarządzanie.
              Systemy i narzędzia wspierające sprzedaż. Zaawansowane projekty serwisów firmowych i platform handlowych.
              Nowoczesne formy wykorzystania technologii mobilnych. Brand marketing i projektowanie strategii komunikacyjnych.
              Projekty dla samorządów. Consulting,doradztwo i szkolenia.">Realizacja
            ProfitCard</a>
        </b-col>
      </b-row>
    </b-container>
  </footer>
</template>

<script>
import NotifyService from '../_services/notify';

const notify = new NotifyService();

export default {
  name: 'Footer',
  methods: {
    breakSession() {
      notify.debug('uszkodzenie sesji');
      localStorage.setItem('token', `dd${localStorage.getItem('token')}z`);
    },
  },
};
</script>
<style scoped lang="scss">
footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 10px;
  border-top: 1px solid #aaa;
  background-color: #efefef;
  font-size: 11px;
}
</style>
