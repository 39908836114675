import Vue from 'vue';

/**
 * Mechanizm powiadomień
 */
export default class notifyService {
  constructor() {
    this.group = 'default';
    this.position = 'top';
    this.duration = 6000;
    this.speed = 300;
    this.notifyExists = {};
    this.canDuplicate = true;
  }

  send(title, message, type = '') {
    if (!this.canDuplicate && this.notifyExists[title]) {
      return;
    }
    this.notifyExists[title] = true;
    Vue.notify({
      type,
      title,
      text: message,
      group: this.group,
      position: this.position,
      duration: this.duration,
      speed: this.speed,
    });
    setTimeout(() => {
      this.notifyExists[title] = false;
    }, this.duration);
  }

  primary(title, message) {
    this.send(title, message);
  }

  success(title, message) {
    this.send(title, message, 'success');
  }

  warn(title, message) {
    this.send(title, message, 'warn');
  }

  error(title, message) {
    this.send(title, message, 'error');
  }

  debug(message) {
    this.send('Debugger', message);
  }
}
