import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navigation: [],
    isLogged: false,
    loading: false,
    shops: null,
    settings: null,
    user_state: null,
    token: localStorage.getItem('token') ?? null
  },
  actions: {
    clearSession() {
      localStorage.removeItem('token');
      this.state.token = null;
    }
  },
  getters: {
    isLogged: (state) => state.isLogged,
  },
  mutations: {
    setSettings(state, settings) {
      state.settings = settings;
    },
    setUserData(state, user_data) {
      state.user = user_data;
    },
    setUserState(state, user_state) {
      state.user_state = user_state;
    },
    setToken(state, token) {
      localStorage.setItem('token', token);
      state.token = token;
    },
  },

});
