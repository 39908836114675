<template>
  <cookie-law storageType="cookie" position="bottom center">
    <div slot-scope="props" class="cookieLaw">
      Korzystanie ze strony oznacza zgodę na wykorzystywanie plików cookie, z których niektóre mogą
      być już zapisane w folderze przeglądarki. Więcej informacji można znaleźć w
      <b-link :to='{"name":"cms","params":{"seo":"polityka-cookies"}}' target="_blank" size="sm"
              variant="primary">Polityce cookies
      </b-link> &nbsp;
      <b-button @click="props.accept" size="sm" variant="success"><span>Akceptuję</span></b-button>
      &nbsp;
      <b-button @click="props.close" size="sm" variant="danger"><span>Zamknij</span></b-button>
    </div>
  </cookie-law>
</template>

<script>
import CookieLaw from 'vue-cookie-law';

export default {
  components: { CookieLaw },
};
</script>
<style scoped lang="scss">
  .Cookie{
    font-size: 12px;
    align-items: center;
    justify-content: center;
  }
</style>
